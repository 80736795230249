import React, {useState, useEffect, useContext, useRef} from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import withAuth from "../../auth";
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

const TruckTypePrices = () => {
    const [truckTypes, setTruckTypes] = useState(null);
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [successes, setSuccesses] = useState({});
    const [errors, setErrors] = useState({});
    const [isUpdating, setIsUpdating] = useState({});
    const [demoDistances, setDemoDistances] = useState({});
    const [calculatedCosts, setCalculatedCosts] = useState({});
    const [isCalculatingDemo, setIsCalculatingDemo] = useState({});
    // useRef to store timers for each truck type
    const timersRef = useRef({});
    const previousDistancesRef = useRef({});

    useEffect(() => {
        const fetchTruckTypes = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin/truck-types/get-all-with-prices`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setTruckTypes(response.data.data);
                initializeDemoStates(response.data.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setError(errorMessages);
                } else if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError('An error occurred. Please try again later.');
                }
            }
        };

        fetchTruckTypes();
    }, [token, navigate]);

    const initializeDemoStates = (truckTypesData) => {
        const initialDistances = {};
        const initialCalculations = {};
        const initialCalculating = {};

        truckTypesData.forEach(truckType => {
            initialDistances[truckType.id] = '';
            initialCalculations[truckType.id] = '0.00';
            initialCalculating[truckType.id] = false;

            previousDistancesRef.current[truckType.id] = '';
        });

        setDemoDistances(initialDistances);
        setCalculatedCosts(initialCalculations);
        setIsCalculatingDemo(initialCalculating);
    };

    useEffect(() => {
        Object.keys(demoDistances).forEach(id => {
            const truckType = truckTypes?.find(tt => tt.id.toString() === id);
            if (truckType) {
                const newDistance = demoDistances[id];
                const oldDistance = previousDistancesRef.current[id];

                // Only proceed if the distance has changed
                if (newDistance !== oldDistance) {
                    // Clear existing timer for this truck type
                    if (timersRef.current[id]) {
                        clearTimeout(timersRef.current[id]);
                    }

                    // Set isCalculatingDemo to true to show spinner for this truck type
                    setIsCalculatingDemo(prev => ({ ...prev, [id]: true }));

                    // Set a new timer for debounce (e.g., 500ms)
                    timersRef.current[id] = setTimeout(() => {
                        const distance = parseFloat(newDistance) || 0;
                        const cost = calculateDeliveryCost(truckType, distance);
                        setCalculatedCosts(prevCosts => ({ ...prevCosts, [id]: cost }));

                        // Hide spinner after calculation
                        setIsCalculatingDemo(prev => ({ ...prev, [id]: false }));

                        // Update the previous distance to the new one
                        previousDistancesRef.current[id] = newDistance;
                    }, 500); // 500ms delay
                }
            }
        });

        // Cleanup function to clear all timers when component unmounts
        return () => {
            Object.values(timersRef.current).forEach(timer => clearTimeout(timer));
        };
    }, [demoDistances, truckTypes]);

    const handleChange = (id, field, value) => {
        setTruckTypes(prevTruckTypes => prevTruckTypes.map(truckType => {
            if (truckType.id === id) {
                if (field in truckType.prices) {
                    return {
                        ...truckType,
                        prices: {
                            ...truckType.prices,
                            [field]: value === '' ? '' : parseFloat(value) // Preserve zero
                        }
                    };
                } else if (field === 'driver_percent') {
                    return {
                        ...truckType,
                        [field]: value === '' ? '' : parseInt(value)
                    };
                }
            }
            return truckType;
        }));
    };

    const handleSubmit = async (e, id) => {
        e.preventDefault();

        if (!window.confirm("Are you sure that you want to update the Truck Type Prices?")) return false;

        setIsUpdating({ ...isUpdating, [id]: true });
        setSuccesses({ ...successes, [id]: '' });
        setErrors({ ...errors, [id]: '' });

        const truckTypeToUpdate = truckTypes.find(truckType => truckType.id === id);
        const endpoint = `${API_URL}/api/admin/truck-types/update-${truckTypeToUpdate.type}-prices`;

        const updateData = {
            ...truckTypeToUpdate.prices,
            driver_percent: truckTypeToUpdate.driver_percent
        };

        try {
            await axios.put(endpoint, updateData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSuccesses({ ...successes, [id]: `${truckTypeToUpdate.title} has been successfully updated` });
            setErrors({ ...errors, [id]: '' });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrors({ ...errors, [id]: errorMessages });
            } else if (error?.response?.data?.message) {
                setErrors({ ...errors, [id]: error.response.data.message });
            } else {
                setErrors({ ...errors, [id]: 'An error occurred. Please try again later.' });
            }
        } finally {
            setIsUpdating({ ...isUpdating, [id]: false });
        }
    };

    const getFormulaDescription = (key, truckType) => {
        switch (truckType) {
            case 'pickup':
                switch (key) {
                    case 'flat_fee':
                        return 'Flat fee charged for the service.';
                    case 'first_radius_km':
                        return 'First radius in km for initial pricing. If distance <= first_radius_km, cost = flat_fee.';
                    case 'fee_after_first_radius':
                        return 'Fee after the first radius per kilometer. If distance <= second_radius_km, cost = (distance - first_radius_km) * fee_after_first_radius + flat_fee.';
                    case 'second_radius_km':
                        return 'Second radius in km for subsequent pricing. If distance > second_radius_km, cost = fee_after_second_radius * distance.';
                    case 'fee_after_second_radius':
                        return 'Fee after the second radius per kilometer.';
                    default:
                        return '';
                }
            case 'flatbed':
                switch (key) {
                    case 'flat_fee':
                        return 'Flat fee charged for the service.';
                    case 'radius_km':
                        return 'Radius in km for pricing. If distance <= radius_km, cost = flat_fee; otherwise, cost = fee_after_radius * (distance - radius_km) + flat_fee.';
                    case 'fee_after_radius':
                        return 'Fee after the radius per kilometer.';
                    default:
                        return '';
                }
            case 'cubevan':
                switch (key) {
                    case 'flat_fee':
                        return 'Flat fee charged for the service.';
                    case 'radius_km':
                        return 'Radius in km for initial pricing. If distance <= radius_km, cost = flat_fee + fee_before_radius * distance.';
                    case 'fee_before_radius':
                        return 'Fee before reaching the radius per kilometer.';
                    case 'fee_after_radius':
                        return 'Fee after exceeding the radius per kilometer. If distance > radius_km, cost = fee_after_radius * distance.';
                    default:
                        return '';
                }
            case 'cargovan':
                switch (key) {
                    case 'flat_fee':
                        return 'Flat fee charged for the service.';
                    case 'radius_km':
                        return 'Radius in km for initial pricing. If distance <= radius_km, cost = flat_fee; otherwise, cost = fee_after_radius * (distance - radius_km) + flat_fee.';
                    case 'fee_after_radius':
                        return 'Fee after the radius per kilometer.';
                    default:
                        return '';
                }
            default:
                return '';
        }
    };

    const calculateDeliveryCost = (truckType, distance) => {
        const { prices } = truckType;
        let cost = 0;

        switch (truckType.type) {
            case 'pickup':
                if (distance <= prices.first_radius_km) {
                    cost = prices.flat_fee;
                } else if (distance <= prices.second_radius_km) {
                    cost = (distance - prices.first_radius_km) * prices.fee_after_first_radius + prices.flat_fee;
                } else {
                    cost = prices.fee_after_second_radius * distance;
                }
                break;

            case 'flatbed':
                cost = distance <= prices.radius_km
                    ? prices.flat_fee
                    : prices.fee_after_radius * (distance - prices.radius_km) + prices.flat_fee;
                break;

            case 'cubevan':
                cost = distance <= prices.radius_km
                    ? prices.flat_fee + prices.fee_before_radius * distance
                    : prices.fee_after_radius * (distance - prices.radius_km) + prices.flat_fee + prices.fee_before_radius * prices.radius_km;
                break;

            case 'cargovan':
                cost = distance <= prices.radius_km
                    ? prices.flat_fee
                    : prices.fee_after_radius * (distance - prices.radius_km) + prices.flat_fee;
                break;

            default:
                cost = 0;
        }

        return cost.toFixed(2);
    };

    const handleDemoDistanceChange = (id, value) => {
        // Remove any non-digit and non-decimal characters
        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        // Ensure only one decimal point is present
        const finalValue = sanitizedValue.split('.').reduce((acc, part, index) => {
            return index === 0 ? part : acc + '.' + part;
        }, '');

        // Optionally, limit to two decimal places
        const limitedValue = finalValue.split('.').length > 1
            ? finalValue.split('.')[0] + '.' + finalValue.split('.')[1].slice(0, 2)
            : finalValue;

        setDemoDistances(prev => ({ ...prev, [id]: limitedValue }));
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Truck Type Prices</h1>
            {error && <div className="alert alert-danger">{error}</div>}
            {truckTypes ? (
                <div className="row">
                    {truckTypes.map(truckType => (
                        <div className="col-md-6" key={truckType.id}>
                            <div className="card">
                                <div className="card-body">
                                    <form key={truckType.id} onSubmit={(e) => handleSubmit(e, truckType.id)}>
                                        <h3 id={"truck-type-" + truckType.id} className="text-center mb-3">{truckType.title}</h3>
                                        {Object.entries(truckType.prices).map(([key, value]) => (
                                            <div key={key} className="form-row mb-2">
                                                <div className="col-md-6">
                                                    <label className="col-form-label">{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase().replaceAll('_', ' ')}</label>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-${key}-${truckType.id}`}>
                                                                {getFormulaDescription(key, truckType.type)}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <InfoCircle className="ml-1 mb-2" style={{ cursor: 'pointer', color: '#007bff' }} />
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={value}
                                                        onChange={(e) => handleChange(truckType.id, key, e.target.value)}
                                                        title={getFormulaDescription(key, truckType.type)}
                                                        readOnly={!hasPermission("truck-type-update")}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="form-row mb-3">
                                            <div className="col-md-6">
                                                <label className="col-form-label font-weight-bold">Driver earnings, %</label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={truckType.driver_percent}
                                                    onChange={(e) => handleChange(truckType.id, 'driver_percent', e.target.value)}
                                                    title="Percentage of the delivery cost that the driver earns. For example, entering 80 means the driver earns 80% of the delivery cost."
                                                    readOnly={!hasPermission("truck-type-update")}
                                                />
                                            </div>
                                        </div>
                                        {successes[truckType.id] && <div className="alert alert-success text-center">{successes[truckType.id]}</div>}
                                        {errors[truckType.id] && <div className="alert alert-danger text-center">{errors[truckType.id]}</div>}
                                        {hasPermission("truck-type-update") && (
                                            <div className="d-flex">
                                                <button type="submit" className="btn btn-primary mx-auto" disabled={isUpdating[truckType.id]}>
                                                    {isUpdating[truckType.id]
                                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Updating...</>
                                                        : 'Update'}
                                                </button>
                                            </div>
                                        )}
                                    </form>

                                    <div className="mt-4">
                                        <h5>Demo: Calculate Pickup Fee for Customer</h5>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Distance, km</span>
                                            </div>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                placeholder="Enter distance"
                                                value={demoDistances[truckType.id] || ''}
                                                onChange={(e) => handleDemoDistanceChange(truckType.id, e.target.value)}
                                                onKeyDown={(e) => {
                                                    // Prevent entering 'e', '+', '-', and other non-numeric characters
                                                    if (
                                                        e.key === 'e' ||
                                                        e.key === 'E' ||
                                                        e.key === '+' ||
                                                        e.key === '-' ||
                                                        e.key === '.' && e.target.value.includes('.')
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                title="Demo: Calculate Delivery Cost"
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text align-content-center" aria-live="polite">
                                                    =&nbsp;&nbsp;
                                                    {isCalculatingDemo[truckType.id] ? (
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <b>${calculatedCosts[truckType.id] || '0.00'}</b>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <h6 className="mt-3 mb-2">
                                            {truckType.id === 1 && <a href="https://navipickups.com/general-driver-contractor-agreement#schedule-b" target="_blank" rel="noopener noreferrer">General Contractor's Agreement > Schedule B</a>}
                                            {truckType.id === 2 && <a href="https://navipickups.com/commercial-driver_contractor-agreement#schedule-b" target="_blank" rel="noopener noreferrer">Flatdeck Commercial Contractor's Agreement > Schedule B</a>}
                                            {truckType.id === 3 && <a href="https://navipickups.com/cubecargo-vans-commercial-contractor-agreement#schedule-b" target="_blank" rel="noopener noreferrer">Cube&Cargo Vans Commercial Contractor's Agreement > Schedule B</a>}
                                            {truckType.id === 4 && <a href="https://navipickups.com/cubecargo-vans-commercial-contractor-agreement#schedule-b" target="_blank" rel="noopener noreferrer">Cube&Cargo Vans Commercial Contractor's Agreement > Schedule B</a>}
                                        </h6>
                                        {truckType.id === 1 && (
                                            <ul>
                                                <li className="mb-2">
                                                    (a) The customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.flat_fee) || 0).toFixed(2)}</b> for Trip Distance up to and including the{' '}
                                                    <b>first {truckType.prices.first_radius_km} km</b>, and an additional{' '}
                                                    <b>${(Number(truckType.prices.fee_after_first_radius) || 0).toFixed(2)} per km</b> for each km of Trip Distance beyond the{' '}
                                                    <b>initial {truckType.prices.first_radius_km} km radius</b>; and
                                                </li>
                                                <li>
                                                    (b) Where the Trip Distance is <b>greater than {truckType.prices.second_radius_km} km</b> in length, the customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.fee_after_second_radius) || 0).toFixed(2)} per each km</b> of Trip Distance.
                                                </li>
                                            </ul>
                                        )}

                                        {truckType.id === 2 && (
                                            <ul>
                                                <li className="mb-2">
                                                    (a) Where the Trip Distance is <b>equal to or less than {truckType.prices.radius_km} km</b> in length, the customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.flat_fee) || 0).toFixed(2)}</b>; and
                                                </li>
                                                <li>
                                                    (b) Where the Trip Distance is <b>greater than {truckType.prices.radius_km} km</b> in length, the customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.flat_fee) || 0).toFixed(2)}</b>, and an additional <b>${truckType.prices.fee_after_radius.toFixed(2)} per km</b>{' '}
                                                    for each km of Trip Distance beyond the <b>initial {truckType.prices.radius_km} km radius</b>.
                                                </li>
                                            </ul>
                                        )}

                                        {(truckType.id === 3) && (
                                            <ul>
                                                <li className="mb-2">
                                                    (a) Where the Trip Distance is <b>equal to or less than {truckType.prices.radius_km} km</b> in length, the customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.flat_fee) || 0).toFixed(2)}</b> and an additional{' '}
                                                    <b>${(Number(truckType.prices.fee_before_radius) || 0).toFixed(2)} per km</b> for each km of Trip Distance; and
                                                </li>
                                                <li>
                                                    (b) Where the Trip Distance is <b>greater than {truckType.prices.radius_km} km</b> in length,{' '}
                                                    the customer will be charged a Trip Fee of{' '}
                                                    <b>
                                                        ${(Number(truckType.prices.flat_fee) || 0).toFixed(2)} + {' '}
                                                        ${(Number(truckType.prices.fee_before_radius) || 0).toFixed(2)} * {truckType.prices.radius_km} km = {' '}
                                                        ${((Number(truckType.prices.flat_fee) || 0) + (Number(truckType.prices.fee_before_radius) || 0) * (Number(truckType.prices.radius_km) || 0)).toFixed(2)}
                                                    </b>,{' '}
                                                    and an additional <b>${(Number(truckType.prices.fee_after_radius) || 0).toFixed(2)} per each km</b> of Trip Distance{' '}
                                                    beyond the <b>initial {truckType.prices.radius_km} km radius</b>.
                                                </li>
                                            </ul>
                                        )}

                                        {(truckType.id === 4) && (
                                            <ul>
                                                <li className="mb-2">
                                                    (a) Where the Trip Distance is <b>equal to or less than {truckType.prices.radius_km} km</b> in length, the customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.flat_fee) || 0).toFixed(2)}</b>; and
                                                </li>
                                                <li>
                                                    (b) Where the Trip Distance is <b>greater than {truckType.prices.radius_km} km</b> in length, the customer will be charged a Trip Fee of{' '}
                                                    <b>${(Number(truckType.prices.flat_fee) || 0).toFixed(2)}</b>,{' '}
                                                    and an additional <b>${(Number(truckType.prices.fee_after_radius) || 0).toFixed(2)} per each km</b> of Trip Distance{' '}
                                                    beyond the <b>initial {truckType.prices.radius_km} km radius</b>.
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuth(TruckTypePrices);
