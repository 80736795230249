import React, {useState, useEffect, useContext, useRef} from 'react';
import axios from 'axios';
import { API_URL, STRIPE_PUBLISH_KEY } from '../../constants';
import {Link, useNavigate, useParams} from 'react-router-dom';
import withAuth from "../../auth";
import { Breadcrumb } from 'react-bootstrap';
import moment from "moment";
import {AuthContext} from "../AuthContext";
import CardForm from "../CardForm";

import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Spinner from "react-bootstrap/Spinner";
import InputMask from "react-input-mask";

const stripePromise = loadStripe(`${STRIPE_PUBLISH_KEY}`);

const UserProfile = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone_num: '',
        progress: '',
        business_name: '',
        designation: '',
        company_email: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEmailVerifySubmitted, setIsEmailVerifySubmitted] = useState(false);
    const [isResendVerificationEmailSubmitted, setIsResendVerificationEmailSubmitted] = useState(false);

    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    const [isNotificationSent, setIsNotificationSent] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const [smsMessage, setSmsMessage] = useState('');
    const [isSmsSent, setIsSmsSent] = useState(false);
    const [showSms, setShowSms] = useState(false);

    const [newReferrerId, setNewReferrerId] = useState('');
    const [isNewReferrerSubmitted, setIsNewReferrerSubmitted] = useState(false);

    const noteForDriverRef = useRef(null);

    useEffect(() => {
        // Fetch the details of the user
        axios.get(`${API_URL}/api/admin/users/${userId}`, {
                headers: {'Authorization': `Bearer ${token}`}
            })
            .then(response => {
                setUser(response.data.data);
            })
            .catch(error => {
                console.error('There was an error fetching the user details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });

        // Fetch payment methods for the user
        getPaymentMethods();
    }, [userId, token]);

    useEffect(() => {
        if (user) {
            setFormData({
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                email: user.email || '',
                phone_num: user.phone_num || '',
                progress: user.progress || '',
                note_for_driver: user.note_for_driver || '',
                business_name: user.company?.business_name || '',
                designation: user.company?.designation || '',
                company_email: user.company?.email || '',
            });
        }
    }, [user]);

    const getPaymentMethods = () => {
        setPaymentMethods(null);
        axios.get(`${API_URL}/api/admin/users/${userId}/payment-methods`, {
            headers: {'Authorization': `Bearer ${token}`},
        })
            .then(response => {
                setPaymentMethods(response.data?.data);
            })
            .catch (error => {
                console.error('Error fetching payment methods', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    };

    const handleDeleteClick = () => {
        if (window.confirm("Are you sure that you want to delete this customer?")) {
            axios.delete(`${API_URL}/api/admin/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    alert('User deleted successfully!');
                    setUser(null);
                    navigate('/users');
                })
                .catch(error => {
                    console.error('There was an error deleting the user!', error);
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('authToken');
                        setToken(null);
                        navigate('/');
                    }
                });
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        if (!window.confirm("Are you sure that you want to update the customer's data?")) {
            return;
        }

        setErrorMessage('');
        setIsSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/users/${userId}`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            alert(response.data.message);
            setUser(response.data.user);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
        finally {
            setIsSubmitted(false);
        }
    };

    const handleSendPushNotification = () => {
        if (!window.confirm("Are you sure that you want to send the push notification to this user?")) return false;

        setErrorMessage('');
        setIsNotificationSent(true);
        axios.post(`${API_URL}/api/admin/users/${userId}/notify`, {title: notificationTitle, body: notificationBody}, {
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(response => {
                alert('Push notification successfully sent!');
                setNotificationTitle('');
                setNotificationBody('');
            })
            .catch(error => {
                console.error('There was an error sending the push notification to the user!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsNotificationSent(false);
            });
    };

    const handleSendSms = () => {
        if (!window.confirm("Are you sure that you want to send the SMS to this customer?")) return false;

        setErrorMessage('');
        setIsSmsSent(true);
        axios.post(`${API_URL}/api/admin/users/${userId}/send-sms`, {message: smsMessage}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert('SMS successfully sent!');
                setSmsMessage('');
            })
            .catch(error => {
                console.error('There was an error sending the SMS to the customer!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsSmsSent(false);
            });
    };

    const handleNewReferrerClick = async () => {
        if (!window.confirm("Could you please confirm if you would like to change the referrer for the customer?")) return false;

        setErrorMessage('');
        setIsNewReferrerSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/users/${userId}/referrer`, {admin_id: newReferrerId}, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            alert(response.data.message);
            setNewReferrerId('');
            setUser(response.data.user);
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
        finally {
            setIsNewReferrerSubmitted(false);
        }
    };

    // Function to render stars based on rating
    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating - fullStars >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {[...Array(fullStars)].map((e, i) =>
                    <i key={`full-${i}`} className="fa fa-star" style={{color: '#FFD700'}}/>
                )}
                {halfStar && <i className="fa fa-star-half-o" style={{color: '#FFD700'}}/>}
                {[...Array(emptyStars)].map((e, i) =>
                    <i key={`empty-${i}`} className="fa fa-star-o" style={{color: '#FFD700'}}/>
                )}
                &nbsp;({rating.toFixed(1)})
            </>
        );
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [formData.note_for_driver]);
    const adjustTextareaHeight = () => {
        if (noteForDriverRef.current) {
            noteForDriverRef.current.style.height = "auto";
            noteForDriverRef.current.style.overflow = "hidden";
            noteForDriverRef.current.style.height = `${noteForDriverRef.current.scrollHeight}px`;
        }
    };

    const handleVerifyEmail = () => {
        setIsEmailVerifySubmitted(true);
        axios.put(`${API_URL}/api/admin/users/${userId}/verify-email`, {},{
            params: {
                status: 'verify',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setUser(response.data.user);
            })
            .catch(error => {
                console.error('There was an error updating the approval status!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setIsEmailVerifySubmitted(false);
            });
    };

    const handleResetEmailVerification = () => {
        setIsEmailVerifySubmitted(true);
        axios.put(`${API_URL}/api/admin/users/${userId}/verify-email`, {},{
            params: {
                status: 'reset',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setUser(response.data.user);
            })
            .catch(error => {
                console.error('There was an error updating the approval status!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setIsEmailVerifySubmitted(false);
            });
    };

    const handleResendVerificationEmail = () => {
        setIsResendVerificationEmailSubmitted(true);
        axios.post(`${API_URL}/api/admin/users/${userId}/resend-verification-email`, {},{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setUser(response.data.user);
            })
            .catch(error => {
                console.error('There was an error updating the approval status!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setIsResendVerificationEmailSubmitted(false);
            });
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/users">Customers</Breadcrumb.Item>
                <Breadcrumb.Item active>Customer Profile</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="text-center" style={{position: 'relative'}}>
                Customer Profile {user ? `#${user.id}` : ''}
                {user && hasPermission('user-update') && (
                    <div className="create-pickup-wrap">
                        <Link to={`/pickups/create/${user.id}`}
                              className='create-pickup-btn btn btn-outline-primary'>
                            Create&nbsp;Pickup
                        </Link>
                    </div>
                )}
            </h2>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {user ? (
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card mb-3">
                            <div className="card-body" align={'center'}>
                                {user.profile_photo &&
                                    <a href={user.profile_photo} target="_blank" rel="noopener noreferrer">
                                        <img src={user.profile_photo} width="100" alt={`${user.name}'s Profile Photo`} className="mb-3" />
                                    </a>
                                }
                                {user.company && (
                                    <div className="mb-2">
                                        <h5 className="card-title">Company</h5>
                                        <p className="card-text">Business Name: <b>{user.company.business_name}</b></p>
                                    </div>
                                )}
                                <p className="card-text">
                                    <b>{user.full_name}</b><br/>
                                </p>
                                {hasPermission('user-update') ? (
                                    <>
                                        {user.company && (
                                            <>
                                                <div className="form-group row mb-2">
                                                    <label className="col-md-3 col-form-label">Business Name:</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="business_name"
                                                            value={formData.business_name}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-2">
                                                    <label className="col-md-3 col-form-label">Designation:</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="designation"
                                                            value={formData.designation}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-2">
                                                    <label className="col-md-3 col-form-label">Company Email:</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="company_email"
                                                            value={formData.company_email}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <hr/>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">Email:</label>
                                            <div className="col-md-9">
                                                <div style={{position: 'relative'}}>
                                                    <input type="text" className="form-control"
                                                           style={{paddingRight: '30px'}}
                                                           name="email"
                                                           value={formData.email} onChange={handleChange}/>
                                                    {user.email_verified_at &&
                                                        <div className="text-success" title="Email Verified"
                                                             style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px'}}>
                                                            <i className="fa fa-check" aria-hidden="true"/>
                                                        </div>
                                                    }
                                                    {!user.email_verified_at &&
                                                        <span className="text-danger" title="Email Unverified"
                                                              style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px'}}>
                                                            <i className="fa fa-times" aria-hidden="true"/>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">Phone:</label>
                                            <div className="col-md-9">

                                                <InputMask className='form-control' mask="+9 (999) 999-9999" name="phone_num" value={formData.phone_num}
                                                           onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">First Name:</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control" name="first_name"
                                                       value={formData.first_name} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">Last Name:</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control" name="last_name"
                                                       value={formData.last_name} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">Progress:</label>
                                            <div className="col-md-9">
                                                <select className="custom-select mr-sm-2" name="progress"
                                                        value={formData.progress} onChange={handleChange}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">Note for Driver:</label>
                                            <div className="col-md-9">
                                                <textarea
                                                    ref={noteForDriverRef}
                                                    value={formData.note_for_driver}
                                                    className="form-control"
                                                    name="note_for_driver"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        adjustTextareaHeight();
                                                    }}
                                                    placeholder="Note for Driver"
                                                    rows={1}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-md-3 col-form-label">New Password:</label>
                                            <div className="col-md-9">
                                                <input type="password" className="form-control" name="password"
                                                       value={formData.password} onChange={handleChange}
                                                       autoComplete="new-password"/>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-primary" onClick={handleSave}>
                                                {isSubmitted
                                                    ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                                 aria-hidden="true"/>&nbsp;Saving...</>
                                                    : "Save"
                                                }
                                            </button>
                                        </div>

                                        <hr/>
                                        <div className="d-flex justify-content-center">
                                            {user.email_verified_at ? (
                                                <button className="btn btn-warning" onClick={handleResetEmailVerification}>
                                                    {isEmailVerifySubmitted
                                                        ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                                     aria-hidden="true"/>&nbsp;Resetting...</>
                                                        : "Reset Email Verification"
                                                    }
                                                </button>
                                            ) : (
                                                <>
                                                    <button className="btn btn-primary" style={{marginRight: '.5em'}} onClick={handleResendVerificationEmail}>
                                                        {isResendVerificationEmailSubmitted
                                                            ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                                         aria-hidden="true"/>&nbsp;Resending...</>
                                                            : "Resend Verification Email"
                                                        }
                                                    </button>
                                                    <button className="btn btn-success" onClick={handleVerifyEmail}>
                                                        {isEmailVerifySubmitted
                                                            ? <><Spinner as="span" animation="border" size="sm" role="status"
                                                                         aria-hidden="true"/>&nbsp;Verifying...</>
                                                            : "Verify Email"
                                                        }
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                        <hr/>
                                        <button className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                                    </>
                                ) : (
                                    <>
                                        <p className="card-text">
                                            Email: <b>{user.email}</b><br/>
                                            Phone: <b>{user.phone_num}</b><br/>
                                            First Name: <b>{user.first_name}</b><br/>
                                            Last Name: <b>{user.last_name}</b>
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="text-center">Details</h5>
                                <div className="card-text">
                                    Average Rating: <b>{renderStars(user.avg_rating || 0)}</b><br/>
                                    Stripe ID: <b>{user.stripe_id}</b><br/>
                                    Sign up Type: <b>{user.signup_type?.signup_type}</b><br/>
                                    <p className="card-text">Registered: <b>{moment(user.created_at).format('YYYY-MM-DD HH:mm')}</b>
                                        <br/>
                                        Last Login: <b>{user.last_login_at ? moment(user.last_login_at).format('YYYY-MM-DD HH:mm') : 'unknown '}</b>
                                        <br/>
                                        IP: <b>{user.last_login_ip || 'unknown'}</b>
                                    </p>
                                    <hr/>
                                    Current Referrer: {user.admin ? (
                                        <Link to={`/coworkers/${user.admin?.id}`}>
                                            <b>{user.admin?.name}</b>
                                        </Link>
                                    ) : <b>Not Set</b>}
                                    {hasPermission('user-update') && (
                                        <div className="form-row align-items-center">
                                            <div className="col-auto">
                                                <input type="number" className="form-control mb-2" name="referrer" placeholder="Referrer ID (Coworker)"
                                                       value={newReferrerId} onChange={e => setNewReferrerId(e.target.value)} autoComplete="new-referrer-id"/>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-dark mb-2" onClick={handleNewReferrerClick}>
                                                    {isNewReferrerSubmitted
                                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Changing...</>
                                                        : "Change"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {hasPermission('user-update') && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5
                                        className="text-center"
                                        style={{ cursor: 'pointer', color: '#007bff' }}
                                        onClick={() => setShowNotification(!showNotification)}
                                    >
                                        Send Push Notification {showNotification ? '▼' : '▶'}
                                    </h5>
                                    <div
                                        className={`fade-section ${showNotification ? 'show' : 'hide'}`}
                                    >
                                        <div className="form-group mb-2">
                                            <input className="form-control" type="text" placeholder="Title" value={notificationTitle}
                                                   onChange={(e) => setNotificationTitle(e.target.value)} />
                                        </div>
                                        <div className="form-group mb-2">
                                            <textarea className="form-control" style={{minHeight: '50px'}} placeholder="Body" value={notificationBody}
                                                      onChange={(e) => setNotificationBody(e.target.value)} />
                                        </div>
                                        <div align={'center'}>
                                            <button className="btn btn-primary"onClick={handleSendPushNotification}>
                                                {isNotificationSent
                                                    ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Sending...</>
                                                    : 'Send'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {hasPermission('user-update') && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5
                                        className="text-center"
                                        style={{ cursor: 'pointer', color: '#007bff' }}
                                        onClick={() => setShowSms(!showSms)}
                                    >
                                        Send SMS {showSms ? '▼' : '▶'}
                                    </h5>
                                    <div
                                        className={`fade-section ${showSms ? 'show' : 'hide'}`}
                                    >
                                        <div className="form-group mb-2">
                                            <textarea className="form-control" style={{minHeight: '50px'}} placeholder="Message" value={smsMessage}
                                                      onChange={(e) => setSmsMessage(e.target.value)} />
                                        </div>
                                        <div align={'center'}>
                                            <button className="btn btn-primary"onClick={handleSendSms}>
                                                {isSmsSent
                                                    ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Sending...</>
                                                    : 'Send'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {hasPermission('user-update') && (
                        <div className="col-xl-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="text-center">Payment methods</h5>
                                    {paymentMethods ? (
                                        paymentMethods.length > 0 ? (
                                            <Elements stripe={stripePromise}>
                                                <ul className="list-group mb-2">
                                                    {paymentMethods.map(method => (
                                                        <li key={method.id} className="list-group-item d-flex justify-content-between">
                                                            <b><span style={{textTransform: "uppercase"}}>{method.card?.brand}</span> **** **** **** {method.card?.last4}</b>
                                                            <div>Expires {method.card?.exp_month.toString().padStart(2, '0')} / {method.card?.exp_year}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Elements>
                                        ) : (<div className="text-center">No payment methods available.</div>)
                                    ) : (
                                        <div className="d-flex justify-content-center" style={{ padding: '20px 0' }}>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                    <hr/>
                                    <h5 className="text-center">Add a new card</h5>
                                    <Elements stripe={stripePromise}>
                                        <CardForm onCardAdded={getPaymentMethods} userId={userId} onError={(msg) => setErrorMessage(msg)}/>
                                    </Elements>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            <style jsx="true">{`
                .create-pickup-wrap {
                    position: absolute; 
                    top: 50%;
                    left: 100%;
                    transform: translate(-100%, -55%);
                }
                @media only screen and (max-width: 768px) {
                    .create-pickup-wrap {
                        position: static;
                        transform: translateX(0);
                    }
                    .create-pickup-btn {
                        padding: .25rem .5rem;
                        font-size: .875rem;
                        line-height: 1.5;
                        border-radius: .2rem;
                    }
                }
                
                .fade-section {
                  max-height: 0;
                  opacity: 0;
                  overflow: hidden;
                  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
                }
                
                .fade-section.show {
                  max-height: 500px;
                  opacity: 1;
                }
                
                .fade-section.hide {
                  max-height: 0;
                  opacity: 0;
                }            
            `}</style>
        </div>
    );
};

export default withAuth(UserProfile);
