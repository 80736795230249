import React, { useEffect, useState, useRef, useMemo } from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import { useGoogleMaps } from './GoogleMapsContext';

const NPMapViewDirections = (props) => {
    const { isLoaded, loadError } = useGoogleMaps();
    const [directions, setDirections] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 49.8951, lng: -97.1384 }); // Winnipeg
    const [shouldCenter, setShouldCenter] = useState(true);

    const directionsServiceRef = useRef(null);
    const prevMapDataRef = useRef({ origins: [], dropoffs: [] });

    // Extract only latitude, longitude, and address for origins and drop-offs
    const mapOrigins = useMemo(
        () => props.origins.map(o => ({
            latitude: o.latitude,
            longitude: o.longitude,
            address: o.address
        })),
        [props.origins]
    );

    const mapDropoffs = useMemo(
        () => props.dropoffs.map(d => ({
            latitude: d.latitude,
            longitude: d.longitude,
            address: d.address
        })),
        [props.dropoffs]
    );

    const toFloat = (n) => parseFloat(parseFloat(n).toFixed(8));

    // Initialize DirectionsService once
    useEffect(() => {
        if (!isLoaded) return;
        if (!directionsServiceRef.current) {
            directionsServiceRef.current = new window.google.maps.DirectionsService();
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!isLoaded || mapOrigins.length === 0 || mapDropoffs.length === 0) return;

        const prevMapData = prevMapDataRef.current;
        const originsChanged = prevMapData.origins.length !== mapOrigins.length ||
            prevMapData.origins.some((o, index) =>
                o.latitude !== mapOrigins[index]?.latitude ||
                o.longitude !== mapOrigins[index]?.longitude ||
                o.address !== mapOrigins[index]?.address
            );

        const dropoffsChanged = prevMapData.dropoffs.length !== mapDropoffs.length ||
            prevMapData.dropoffs.some((d, index) =>
                d.latitude !== mapDropoffs[index]?.latitude ||
                d.longitude !== mapDropoffs[index]?.longitude ||
                d.address !== mapDropoffs[index]?.address
            );

        if (!originsChanged && !dropoffsChanged) return;

        prevMapDataRef.current = { origins: mapOrigins, dropoffs: mapDropoffs };

        setDirections(null);

        const directionsService = directionsServiceRef.current;
        if (!directionsService) return;

        // Ensure valid start and end points
        const originPoint = mapOrigins[0];
        const destinationPoint = mapDropoffs[mapDropoffs.length - 1];

        if (!originPoint || !destinationPoint) return;

        // Set up waypoints excluding first origin and last dropoff
        const waypoints = [
            ...mapOrigins.slice(1),
            ...mapDropoffs.slice(0, -1),
        ].map((point) => ({
            location: new window.google.maps.LatLng(toFloat(point.latitude), toFloat(point.longitude)),
            stopover: true,
        }));

        const request = {
            origin: new window.google.maps.LatLng(toFloat(originPoint.latitude), toFloat(originPoint.longitude)),
            destination: new window.google.maps.LatLng(toFloat(destinationPoint.latitude), toFloat(destinationPoint.longitude)),
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: false,
        };

        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);

                if (props.onWaypointsOptimized) {
                    const optimizedWaypoints = result.routes[0].waypoint_order.map((index) => waypoints[index]);
                    props.onWaypointsOptimized(optimizedWaypoints);
                }

                if (props.onDistanceCalculated) {
                    const totalDistance = result.routes[0].legs.reduce((acc, leg) => acc + leg.distance.value, 0);
                    const distanceInKm = totalDistance / 1000;
                    props.onDistanceCalculated(distanceInKm);
                }

                if (shouldCenter) {
                    setMapCenter({
                        lat: toFloat(originPoint.latitude),
                        lng: toFloat(originPoint.longitude),
                    });
                    setShouldCenter(false);
                }
            } else {
                console.error("Error fetching directions:", status);
            }
        });

    }, [isLoaded, mapOrigins, mapDropoffs, shouldCenter, props.onWaypointsOptimized, props.onDistanceCalculated]);

    useEffect(() => {
        setShouldCenter(true);
    }, [mapOrigins, mapDropoffs]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            center={mapCenter}
            zoom={13}
        >
            {directions && (
                <DirectionsRenderer directions={directions} />
            )}
        </GoogleMap>
    );
};

export default NPMapViewDirections;
